import {
  Box,
  Container,
  HStack,
  Stack,
  Text,
  Button,
  Center,
  Image,
  Wrap,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import React from "react";
import file from "./Assets/app.apk";
import image from "./Assets/Group4.png";
import image2 from "./Assets/photo.png";
import card2 from "./Assets/card2.png";
import air from "./Assets/air.png";
import water from "./Assets/water.png";
import ethio from "./Assets/ethio.png";
import school from "./Assets/school.png";
import dstv from "./Assets/dstv.jpg";
// import commerc from "./Assets/commerc.png";
// import dashen from "./Assets/dashen.png";
// import boa from "./Assets/boa.jpg";
// import zemen from "./Assets/zemen.png";
// import enat from "./Assets/enat.png";
// import oromia from "./Assets/oromia.jfif";
// import awash from "./Assets/awash.png";
// import anbesa from "./Assets/anbesa.jpg";
import logo from "./Assets/Group 3.png";

import abyssinia from "./Assets/abyssinia bank.png";
import awash from "./Assets/awash bank.png";
import dashen from "./Assets/Dashen bank .png";
import zemen from "./Assets/zemen bank.png";
import cbe from "./Assets/cbe.png";
import coop from "./Assets/coop bank.png";
import wegagen from "./Assets/wegagen bank.png";
import bunna from "./Assets/bunna bank.png";
import nib from "./Assets/nib bank.png";

import m1 from "./Assets/1.png";
import m2 from "./Assets/2.png";
import m3 from "./Assets/3.png";
import m4 from "./Assets/4.png";
import m5 from "./Assets/5.png";
import m6 from "./Assets/6.png";
import hibret from "./Assets/hibret bank.png";
import Navbarpage from "./Navbar";
import { saveAs } from "file-saver";
// import MobileStoreButton from "react-mobile-store-button";
export default function Homepage() {
  const downloadFile = () => {
    window.location.href = file;
    saveAs(file, "hulum-pay.apk");
  };
  const Bank = [
    cbe,
    abyssinia,
    awash,
    dashen,
    zemen,
    hibret,
    coop,
    wegagen,
    bunna,
    nib,
  ];
  const Services = [
    {
      image: m1,
      text: "ከባንክ  ወደ ባንክ የገንዘብ ዝውውር  ",
      text2: "Money Transfers",
    },
    { image: m2, text: "ማንኛውንም  ክፍያ ይፈፅሙ ", text2: " Bill Payments" },
    { image: m3, text: "የሞባይል ካርድ ", text2: "Mobile Airtime" },
    {
      image: m4,
      text: "ያለካርድ አገልግሎት ",

      text2: "Cardless Service",
    },
    { image: m5, text: "ከውጭ ሀገር የሚደረጉ ክፍያዎች ", text2: "Payments from Abroad" },
    { image: m6, text: "የትምህርት ቤት ክፍያዎች ", text2: "Schools Fees" },
  ];
  return (
    <div>
      <Box
        // w={"100%"}
        // h={{ base: "300vh", md: "200vh", lg: "200vh" }}
        // bgImage={`url(${image})`}
        // bgPosition="center"
        // bgSize="cover"
        // bgRepeat="no-repeat"
        // bgImage="url(./Assets/Group 4.png)"
        bg={"#B0CB00"}
      >
        {/* <Navbarpage></Navbarpage> */}
        <Container maxW="container.xl">
          <br />
          <Center>
            <Image
              borderRadius={"20vh"}
              objectFit="cover"
              width={{ base: "30vh", md: "30vh", lg: "30vh" }}
              height={{ base: "8vh", md: "8vh", lg: "8vh" }}
              src={logo}
              alt="logo"
            />
          </Center>
          <br />
          <Center mt={"5vh"}>
            <Box borderRadius={"6vh"} padding={4} w={"60vh"} bg={"white"}>
              <Center>
                <Image
                  borderTopLeftRadius={"2vh"}
                  borderTopRightRadius={"2vh"}
                  h={"6vh"}
                  width={"10vh"}
                  objectFit="cover"
                  src={image2}
                  alt="image"
                />
              </Center>
              <Text
                fontSize={{ base: "16px", md: "18px", lg: "18px" }}
                color={"#A7BD05"}
                fontWeight={"bold"}
                padding={"1vh"}
              >
                ከየትኛውም ባንክ ላሻዎት ጉዳይ በቀላሉ ገንዘብ ለመላክ የሚያስችል ብቸኛ አማራጭ
              </Text>
              <Text
                mt={2}
                fontSize={{ base: "16px", md: "18px", lg: "18px" }}
                color={"#0395c1"}
                fontWeight={"bold"}
                padding={"1vh"}
              >
                First of its kind in Ethiopia <br /> From available banks to
                anyone
              </Text>
            </Box>
          </Center>
          <br />
          <Center></Center>
          <Center>
            <Text
              fontSize={{ base: "20px", md: "20px", lg: "20px" }}
              color={"#f4f7de"}
              fontWeight={"bold"}
              padding={"1vh"}
            >
              መተግበሪያውን ያውርዱ <br></br>
              Download the app
            </Text>
          </Center>
          <Center>
            <Button
              height={"6vh"}
              w={"20vh"}
              onClick={downloadFile}
              borderRadius={"2vh"}
              colorScheme="blue"
              bg={"#0395c1"}
              // variant="outline"
              fontSize={"20px"}
            >
              Download
            </Button>
          </Center>
          <Stack>
            <Center mt={8}>
              <Wrap align={"center"} justify={"center"} spacing={2}>
                {Bank.map((item, index) => {
                  return (
                    <WrapItem key={index}>
                      <Box borderRadius={"2vh"}>
                        <Image
                          h={{ base: "12vh", md: "30vh", lg: "30vh" }}
                          width={{ base: "20vh", md: "100%", lg: "100%" }}
                          borderLeftRadius={"2vh"}
                          borderRightRadius={"2vh"}
                          objectFit="fill"
                          src={item}
                          alt="image"
                        />
                      </Box>
                    </WrapItem>
                  );
                })}
              </Wrap>
            </Center>
          </Stack>
          <Stack mt={4}>
            <Text
              ml={"2vh"}
              mb={"2vh"}
              // textAlign={"left"}
              fontSize={{ base: "20px", md: "22px", lg: "22px" }}
              color={"#f4f7de"}
              fontWeight={"bold"}
              padding={"1vh"}
            >
              የምንሰጣቸው አገልግሎቶች <br></br>
              Services we Provide
            </Text>

            <Center>
              <Wrap align={"center"} justify={"center"} spacing={4}>
                {Services.map((item, index) => {
                  return (
                    <WrapItem key={index}>
                      <Box
                        borderRadius={"6vh"}
                        h={{ base: "20vh", md: "25vh", lg: "25vh" }}
                        w={{ base: "20vh", md: "25vh", lg: "25vh" }}
                        border={"6px solid white"}
                      >
                        <Center>
                          <Image
                            h={"10vh"}
                            width={"10vh"}
                            borderLeftRadius={"2vh"}
                            borderRightRadius={"2vh"}
                            objectFit="fill"
                            src={item.image}
                            alt="image"
                          />
                        </Center>
                        <Center
                          fontSize={{ base: "13px", md: "19px", lg: "19px" }}
                          color={"#f4f7de"}
                        >
                          {item.text}
                        </Center>
                        <Center
                          fontSize={{ base: "13px", md: "18px", lg: "18px" }}
                          color={"#f4f7de"}
                        >
                          {item.text2}
                        </Center>
                      </Box>
                    </WrapItem>
                  );
                })}
              </Wrap>
            </Center>
          </Stack>
          <br /> <br />
          <Center mt={6}>
            <Wrap>
              <WrapItem>
                <Avatar
                  name="Facebook"
                  src="https://imgs.search.brave.com/U95P3VyYAeYNRMHO3AHeqNF49Edg4L8Td_hhO9EOydI/rs:fit:474:225:1/g:ce/aHR0cHM6Ly90c2Ux/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5o/R2FldERBUVdhcGdJ/SmJJT2hQaFh3SGFI/YSZwaWQ9QXBp"
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  size={"md"}
                  name="Intragram"
                  objectFit={"contain"}
                  src="https://imgs.search.brave.com/bu_MhW41aGRhRgSgHjaWRs0m8KJB6Us6JVF0XK9tuNk/rs:fit:474:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5n/LUJGR1ZRX2lVdGwz/UTRlWHJmbWdnSGFI/YSZwaWQ9QXBp"
                />
              </WrapItem>
            </Wrap>
          </Center>
          <Center mt={3} color={"#f4f7de"}>
            secure, reliable and easy to use
          </Center>
          <br />
          <Center mt={6} color={"#f4f7de"}>
            All Rights Reserved,2022 Hulum pay Inc.
          </Center>
        </Container>
      </Box>
    </div>
  );
}
